import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Button from 'button';

import s from './title-two-text.module.scss';

export default function TitleTwoText() {
  return (
    <Container className={s.root}>
      <Headline h1 el="h3" className={s.title} dash smallCenter>
      BUILDING CONNECTIONS
      </Headline>
      <div className={s.wrapper}>
        <p className={s.text}>
        Our team goes the extra mile to make sure you feel connected to us and our process. What we do for you can mean everything to your brand which is why we keep our client involved and their vision in mind. We’re simple and hardworking, but make sure to stay at the forefront of technological and strategic innovations. That’s why CXN can deliver effective results without sacrificing creativity and elegance.
        </p>
        <p className={s.text}>
        Though standardized and efficient, our internal process is still flexible—so we can make sure we’re meeting your specific, custom needs. Your needs might change or adjust! No worries. We’re here for that! In order to keep up with the fast-paced digital world, we make sure your top-performing site is one-of-a-kind.
        </p>
      </div>
      <div className="text-center">
        <Button href="/our-work">See some connections we've made</Button>
      </div>
    </Container>
  );
}
