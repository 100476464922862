import React from 'react';
import Container from 'container';
import Headline from 'headline';

import s from './culture-message.module.scss';

export default function CultureMessage() {
  return (
    <Container className={s.root}>
      <Headline className={s.title} h1 dash center white>
      MOTIVATED INDIVIDUALS
        <span className={s.span}>UNMATCHED COMPANY CULTURE</span>
      </Headline>
      <Headline className={s.subtitle} h3 center white>
        UNMATCHED COMPANY CULTURE
      </Headline>
      <p className={s.text}>
      Our team drive and company spirit make for truly extraordinary results. We’ve built an environment of individuals who trust and rely on each other. CXN’s employees have that spark, and we’re constantly bringing new skills into the fold. Our energy and vigor doesn’t stop us from first listening, however–both to the client and to each other. We’ve learned from our mistakes and perfected our process, but our core values of collaboration and hard work have remained the same. Our success comes from this culture of encouragement and teamwork; and of course our never-ending pool of talent.
      </p>
    </Container>
  );
}
