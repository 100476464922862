import React from 'react';
import Meta from 'meta';
import Hero from 'components/page-about/hero';
import TitleTwoText from 'components/page-about/title-two-text';
import ImageLogo from 'components/page-about/image-logo';
import TeamGrid from 'components/page-about/team-grid';
import CultureMessage from 'components/page-about/culture-message';
import ClientsSection from 'components/page-about/clients-section';
import LetsStart from 'shared/lets-start';

export default function About(props) {
  return (
    <>
      <Meta
        title="Miami Based Digital Agency | Connections Colletive"
        description="Creativity and purpose meet technical expertise to address every aspect of your user’s digital experience & provide measurable results."
        canonical="https://cxncollective.com/about/"
      />
      <Hero />
      <main>
        <TitleTwoText />
        <ImageLogo />
        {/* <TeamGrid /> */}
        <CultureMessage />
        <ClientsSection />
        <LetsStart />
      </main>
    </>
  );
}
