import React from 'react';
import ImageSection from 'shared/image-section';
import Icon from 'icon';

import s from './image-logo.module.scss';

export default function ImageMessage() {
  return (
    <ImageSection className={s.root} src="cxn-office">
      <Icon icon="logo-solid" className={s.logo} />
    </ImageSection>
  );
}
