import React from 'react';
import Container from 'container';
import Headline from 'headline';
import Img from 'img';

import s from './clients-section.module.scss';

const clients = [
  'intermiami',
  'boomer',
  'flhone',
  'ecs',
  'nofault',
  'laking',
  'connections',
  'cor',
  'furlough',
  'cannapan',
  'shock',
  'aeon',
  'prive',
  'pichy',
  'fortune',
  'cm',
  'pw',
  'cohort'
];

export default function ClientsSection() {
  return (
    <Container className={s.root}>
      <Headline h3 dash smallCenter className={s.title}>
      An interpersonal digital network
      </Headline>
      <Headline h1 smallCenter className={s.subtitle}>
        Our clients
      </Headline>
      <div className={s.clientWrapper}>
        {clients.map(client => (
          <div className={s.client} key={client}>
            <Img
              src={`logo-${client}`}
              key={`logo-${client}`}
              className={s.clientImage}
            />
          </div>
        ))}
      </div>
    </Container>
  );
}
